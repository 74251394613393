import {Component} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './services/auth.service';
import {EventService} from './services/event.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public currentLanguage: string;
    public username: string;

    constructor(
        private platform: Platform,
        private menu: MenuController,
        private translate: TranslateService,
        private authService: AuthService,
        private eventService: EventService
    ) {
        this.authService.onLoginSuccess.subscribe(() => this.username = this.authService.getUsername());
        this.initializeApp();
    }

    private initializeApp() {
        this.platform.ready().then(() => {
            // this language will be used as a fallback when a translation isn't found in the current language
            this.translate.setDefaultLang('en');

            // Set users preferred language
            this.currentLanguage = navigator.language;
            this.translate.use(this.currentLanguage);
        });

        this.username = this.authService.getUsername();
    }

    public async doLogout(): Promise<void> {
        await this.closeMenu();
        await this.authService.logout();
    }

    public dispatchEvent(eventName) {
        setTimeout(() => {
            this.eventService[`${eventName}$`].emit();
        }, 500);
    }

    public async closeMenu(): Promise<void> {
        await this.menu.close('mainMenu');
    }
}
