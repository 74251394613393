import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Exercise} from '../../models/excercise';

@Component({
    selector: 'form-modal',
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent implements OnInit {
    @Input() mode: string;
    @Input() form: any;

    private fieldOrder: string[];

    public orderedForm: any[] = [];

    constructor(private modalCtrl: ModalController) {
    }

    public ngOnInit() {
        // console.log('Modal object', this.form);

        for (const field of this.form.fieldOrder) {
            if (this.form[field]) {
                this.orderedForm.push(this.form[field]);
            }
        }

        // console.log('Ordered Form: ', this.orderedForm);
    }

    public dismiss() {
        // console.log('Dismiss form modal without data');
        this.modalCtrl.dismiss();
    }

    public onSubmit(formData: any[]) {
        // console.log('Form Data before merge', formData);

        for (const field of formData) {
            if (field.hasOwnProperty(name)) {
                this.form[field.name] = field;
            }
        }

        // console.log('Dismiss form modal with data', this.form);
        this.modalCtrl.dismiss(this.form);
    }

    public trackByFn(index): number {
        return index;
    }

    public matchFieldType(field, type): boolean {
        return (field.element === type);
    }
}
