import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  public closeMenu$ = new EventEmitter<any>();
  public pageReady$ = new EventEmitter<any>();
  public randomExercise$ = new EventEmitter<any>();

  constructor() {}
}
