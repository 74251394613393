import {EventEmitter, Injectable, OnDestroy, Output} from '@angular/core';
import {Router} from '@angular/router';
import {FirestoreService} from './firestore.service';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {User} from '../models/user';
import {Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    @Output() onLoginSuccess = new EventEmitter();
    @Output() onLoginFailure = new EventEmitter();

    @Output() onLogOut = new EventEmitter();

    private authStateSubscriber: Subscription;
    userData: any;

    public user: {
        timestamp: string,
        username: string
    } = {} as any;

    constructor(
        private router: Router,
        private firestore: FirestoreService,
        private fireauth: AngularFireAuth
    ) {
        this.authStateSubscriber = this.fireauth.authState.subscribe(user => {
            if (user) {
                this.userData = user;
                localStorage.setItem('user', JSON.stringify(this.userData));
                JSON.parse(localStorage.getItem('user'));
            } else {
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
            }
        });
    }

    public ngOnDestroy(): void {
        this.authStateSubscriber.unsubscribe();
    }

    public async login(email: string, password = 'password') {
        /*
        return this.fireauth.auth.signInWithEmailAndPassword(email, password)
            .then((result) => {
                console.log('Login as user', result.user.displayName);
                this.setUserData(result.user);
                this.onLoginSuccess.emit();
            }).catch((error) => {
                console.error(error);
                this.onLoginFailure.emit();
            });
         */


        this.user.username = email;
        this.user.timestamp = new Date().toISOString();

        try {
            console.log('Login as user', email);
            localStorage.setItem('loginName', email);
            await this.firestore.addItem(this.user, 'Login Stats', this.user.timestamp);

            this.onLoginSuccess.emit();
        } catch (error) {
            console.error(error);
            this.onLoginFailure.emit();
        }
    }

    public async logout(): Promise<void> {
        this.onLogOut.emit();

        return this.fireauth.auth.signOut().then(() => {
            localStorage.removeItem('user');
            this.router.navigateByUrl('login');
        });
    }

    setUserData(user) {
        const userRef: AngularFirestoreDocument<any> = this.firestore.getDocument(`users/${user.uid}`);
        const userData: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified
        };
        return userRef.set(userData, {
            merge: true
        });
    }

    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user !== null && user.emailVerified !== false);
    }

    public getUsername(): string {
        if (!localStorage.getItem('loginName')) {
            console.log('User not logged in. Redirecting to login page.');
            this.router.navigateByUrl('login');
        } else {
            return localStorage.getItem('loginName');
        }
        // return 'User';
    }
}
