import {Component, Input, OnInit} from '@angular/core';
import {Listfield} from '../../../models/fields/listfield';

@Component({
    selector: 'list-field',
    templateUrl: './list-field.component.html',
    styleUrls: ['./list-field.component.scss'],
})
export class ListFieldComponent implements OnInit {
    @Input() mode: string;
    @Input() placeholder: string;
    @Input() name: string;
    @Input() list: string[];

    constructor() {
    }

    ngOnInit() {
    }

}
