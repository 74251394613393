import {Injectable} from '@angular/core';

import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import {User} from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class FirestoreService {


    constructor(
        private firestore: AngularFirestore,
        private fireauth: AngularFireAuth
    ) {
    }

    public getDocument(path: string): AngularFirestoreDocument<any> {
        return this.firestore.doc(path);
    }

    public getCollection(path: string): Observable<any[]> {
        return this.firestore.collection(path).valueChanges();
    }

    public getSnapshot(path: string): Observable<any[]> {
        return this.firestore.collection(path, ref => ref.orderBy('name', 'asc')).snapshotChanges();
    }

    public async addItem(item: any, collection: string, document?: string) {
        try {
            if (document) {
                await this.firestore.collection(collection).doc(document).set(item);
            } else {
                await this.firestore.collection(collection).add(item);
            }
        } catch (error) {
            console.error(error);
        }
    }
}
