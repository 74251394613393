import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAnalyticsModule} from '@angular/fire/analytics';
import {AngularFireAuthModule} from '@angular/fire/auth';

import {environment} from '../environments/environment';
import {FirestoreService} from './services/firestore.service';
import {FormModalComponent} from './components/form-modal/form-modal.component';
import {FormsModule} from '@angular/forms';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AuthService} from './services/auth.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ServiceWorkerModule} from '@angular/service-worker';
import {ListFieldComponent} from './components/fields/list-field/list-field.component';
import {EventService} from './services/event.service';

@NgModule({
    declarations: [
        AppComponent,
        FormModalComponent,
        ListFieldComponent
    ],
    entryComponents: [FormModalComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireAnalyticsModule,
        AngularFireAuthModule,
        FormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        FirestoreService,
        AuthService,
        TranslateService,
        EventService
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

